import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import MenuSamples from './MenuSamples.js';
import Howitworks from './Howitworks.js';
import Pricing from './Pricing.js';
import Navbar from './Navbar.js';
import CartBar from './CartBar.js';
import { BsChevronRight } from 'react-icons/bs';
import Spinner from 'react-bootstrap/Spinner';
// imagekit io
const urlEndpoint = 'https://ik.imagekit.io/your_imagekit_id';
const publicKey = 'lys916'; 

export default function Home(props) {
  const [loadingImage, setLoadingImage] = useState(true);

  // console.log('Products list in Home component', props.products);

  console.log('PRODUCTS', props.products);
  // push out of stock product to end of the list
  props.products.sort((a, b)=>{
    return b.inStock - a.inStock;
  });


  // useEffect(() => {
  //   console.log('RRRRRRRRRRRRRRR useeffect at Home', props.products[0].inStock );
  //   const cloneProducts = structuredClone(props.products);
  //   cloneProduct[0].inStock = 23;

  // }, [])

  return (
    <div className="home">
      <Navbar />
      <CartBar carts={props.carts}/>
      
      {/* BANNER */}
      <div className="a-banner-bg">
        <img className="a-banner-img" src={require('./IMG/weedbanner.jpg')} />
        <div className="a-welcome">
          <h3>High quality weed delivered to your doorsteps. Best prices guaranteed.</h3>
        </div>
      </div>
      <br/>


      <div className="free-shipping">
        Order minimum of $29 and get free stardard shipping or minimum of $59 and get free expedited shipping.
      </div>
    
      
      {/* PRODUCT LIST */}
      <div className="product-list">
      {props.products.map((p)=>{
        return (
          <Link className="linklink" to="/product" state={{ product: p }} key={p.uid}>
          
          

          <div className="product-box">

            {loadingImage ? <div className="loading-overlay"><Spinner animation="border" variant="secondary" style={{marginTop: '45px'}}/></div> : '' }

            
            <div className="product-img">
              
              <img className="weed-img" src={p.image} onLoad={()=>{setLoadingImage(false)}} />

              {p.inStock < 1 ? <div className="outofstock">Out of stock</div> : ''}
              
            </div>
            <div className="product-desc">
              <div className="product-name">{p.name}</div>
              <div className="desc-box">

                <div className="product-grade desc">Grown In: {p.grade}</div>

                
                
                <div className="product-thc desc">THC: {p.thc}</div>
                <div className="product-type desc">Strain: {p.type}</div>
                <div className="product-price desc">${p.priceGram} / 3.5g</div>
              </div>
            </div>
            <div className="right-arrow">
            <BsChevronRight />
            </div>
        </div>
        </Link>
        );
      })}  
      </div>      
    </div>
  )
}
