import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react'
import { BsCart } from 'react-icons/bs';
import { BsCart2 } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';

export default function CartBar(props) {
  const [menuStatus, setMenuStatus] = useState('');

  // console.log('CCCCCCCCCCCCC in cart bar', props.carts );
  
  const getTotalPrice = ()=>{
    let total = 0;
    props.carts.forEach((item)=>{
      total = total + item.totalPrice;
    });
    return total;
  }

  const getQty = ()=>{
    let total = 0;
    props.carts.forEach((item)=>{
      total = total + item.qty;
    });
    return total;
  }

  return (
    <div className='cartbar'>
      <div className="cart-number">{getQty()}</div>
      <div className="cart-left">
        <div className="footer-cart"><BsCart size={45} /></div>
        <div className="footer-total">Total: ${Math.floor(getTotalPrice())}</div>
      </div>

      
          
          

          {Math.floor(getTotalPrice()) > 0 ? 
          <Link to="/cart">
            <div className="footer-checkout">
              <Button variant="success"><span className="checkout-name">Checkout</span></Button></div>
          </Link> :
            <div className="footer-checkout">
              <Button disabled variant="secondary"><span className="checkout-name">Checkout</span></Button></div>
          }

          

        
    </div>
  )
}

