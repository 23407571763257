import React, {useState, useEffect} from 'react'
import Navbar from './Navbar.js';


export default function Concentrates() {

  
  // window.blockonomicsAsyncInit = function () {
  //   setTimeout(() => {
  //       Blockonomics.widget({
  //         msg_area: 'bitcoinpay',
  //         uid: 'cccd5ee4cc364990',
  //         email: 'lysdfkl@gmail.com'
  //       })
  //     }, 1000)

  // }

  // useEffect(()=>{
  //   // window.blockonomicsAsyncInit = function () {
   
  //   //   console.log('sldkfj', window);
  //   // }
  //   // window.Block = Blockonomics;
  //   // console.log( 'useeffect', window
  //   // );

  // }, []);




  return (
    <div>
      <Navbar />
      <div style={{marginTop: '70px'}}>

       
        Concentrates products coming soon.
        <div id="bitcoinpay"></div>
        <div>
          {/* {   window.Block.widget({
           msg_area: 'bitcoinpay',
          uid: 'cccd5ee4cc364990',
           email: 'lysdfkl@gmail.com'
         })} */}
        </div>

      </div>
      
    </div>
  )
}

