import React, {useState, useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import { BsArrowLeft } from 'react-icons/bs';
import { BsXLg } from 'react-icons/bs';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, Link } from 'react-router-dom';


export default function Cart(props) {
  // console.log('CARTS', props.carts);
  const navigate = useNavigate();

  const [removeLoading, setRemoveLoading] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);


  const delayRemove = (index)=>{
    setTimeout(()=>{
      props.removeFromCart(index)
      setRemoveLoading(null);
    }, 700);
    setRemoveLoading(index);
  }

  const getTrueAmount = (amount)=>{
    
    if(Number(amount) > 8 ){ return ( Number(amount) * 3.5 ) / 28 + ' ounces'}
    if(Number(amount) < 9 ){ return (Number(amount) * 3.5) + ' grams'}

  }

  return (
    <div>
    {props.loading ? <Spinner animation="border" variant="warning" style={{marginTop: '65px'}}/>:
    // MAIN DIV ------
    <div className="cart-wrapper">

      <div className="item-arrowback-box">
        <div className="cart-back" onClick={()=>{navigate(-1)}}><BsArrowLeft size={40}/></div>

        <div className="cart-x-nav" onClick={()=>{navigate('/')}}><BsXLg size={25}/></div>
      </div>

      {/* <div className="cart-back" onClick={()=>{navigate(-1)}}><BsArrowLeft size={40}/></div> */}
      
      <div className="your-cart-name">Your Cart</div>
      {props.carts.map((item, index)=>{
        // console.log('item', item);
        return (
          <div key={item.uid}>
            <div className="cart-item-box overlay shadow-bottom">

              {removeLoading == index ? <div className="cart-item-spinner"><Spinner animation="border" variant="danger"/></div> : '' }
              
              <div className="cart-item-left">
                <div className="cart-qty-box">
                  <div className="qty-things qt-minus" onClick={()=>{props.changeQty(item, 'plus')}}>+</div>
                  <div className="qty-things qt-number">{item.qty}</div>
                  <div className="qty-things qt-plus" onClick={()=>{props.changeQty(item, 'minus')}}>-</div>
                </div>

                <div className="cart-img-box">
                  {loadingImage ? <Spinner animation="border" variant="secondary"/> : '' }

                  <img className="a-banner-img" src={item.image} onLoad={()=>{setLoadingImage(false)}}/>

                </div>

                <div className="cart-desc-box">
                  <div className="cart-item-name">{item.name}</div>
                  <div className="cart-item-desc">{item.grade}</div>
                  <div className="cart-item-desc">{item.thc} &#9679; {item.type}</div>
                  {/* <div className="cart-item-desc">{getTrueAmount(item.amount)}</div> */}

                  <div className="cart-item-desc">${item.priceGram} / 3.5 grams</div>

                  

                  <div className="cart-item-price"> {getTrueAmount(item.amount)} / ${item.totalPrice}</div>


                </div>
              </div>
              <div className="cart-x" onClick={()=>{delayRemove(index)}}>x</div>
            </div>
           
          </div>
        )
      })}
      {/* if cart is empty */}
      {props.carts.length == 0 ? <div style={{textAlign: 'left', paddingLeft: '20px', fontSize: '22px'}}>is empty<br/><br/><br/></div> : ''}

        {/* buy more and get free shipping message */}
        
          <div>
            {props.getCartTotal() > 0 && props.getCartTotal() < 29  ? `Spend $${ 29 - props.getCartTotal()} more and get free standard shipping or spend $${ 59 - props.getCartTotal()} more and get free expedited shipping` : ''}
          </div>
          <div>
            {props.getCartTotal() > 28 && props.getCartTotal() < 59 ? `Spend $${59 - props.getCartTotal()} more and get free expedited shipping` : ''}
          </div>

      {props.carts.length > 0 ? <> 
      
      <div className="total-wrapper shadow-bottom">
        <div className="cart-total-wrapper">

          <div className="cart-total-box">
            <div>Cart total</div>
            <div>${props.getCartTotal()}</div>
          </div>

          <div className="cart-total-box">
            <div style={{display: 'flex'}}>
              <input type="radio" value="Male" name="gender" checked={props.shipping} onChange={()=>{props.changeShipping(true)}}/>
              &nbsp;
              <div>Shipping (5-7 days)</div>
            </div>
            <div>{props.getShipping() == 0 ? 'Free' : '$' + props.getShipping()}</div>
          </div>

        

          <div className="cart-total-box">
             <div style={{display: 'flex'}}>

              <input type="radio" value="Male" name="gender" checked={!props.shipping} onChange={()=>{props.changeShipping(false)}}/>
              &nbsp;
              <div>Shipping (2-3 days)</div>

            </div>
            <div>{props.getExpShipping() == 0 ? 'Free' : '$' + props.getExpShipping() }</div>
          </div>

        </div>

        {props.shipping ? <div className="cart-grandtotal">
          <div>Total</div>
          <div>${props.getCartTotal()+props.getShipping()}</div>
        </div> : <div className="cart-grandtotal">
          <div>Total</div>
          <div>${props.getCartTotal()+props.getExpShipping()}</div>
        </div>}
        
      </div>

      
      
      <br/>
      <Link to='/checkout'>
      <Button variant="success" size="lg" className="proceed-btn">Proceed to Checkout</Button>
      </Link>
      <br/>< br/>
      <br/>< br/>


      </> : ''}
      
    </div>
    
    
    }
    </div>
    
    
  )
}
