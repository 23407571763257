import React, { useState, useEffect } from 'react';
import { collection,updateDoc, where, addDoc, getDocs, doc,query, deleteDoc, getDoc, onSnapshot, orderBy, increment } from 'firebase/firestore';

import { db } from './firebase-config';
import axios from "axios";

import './App.css';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './Home.js';
import StartPlan from './StartPlan.js';
import Product from './Product.js';
import ThankYou from './ThankYou.js';
import Employee from './Employee.js';
import Order from './Order.js';
import Menu from './Menu.js';
import Checkout2 from './Checkout2.js';
import Checkout from './Checkout.js';
import Cart from './Cart.js';
import Concentrates from './Concentrates.js';
import Edibles from './Edibles.js';
import Contactus from './Contactus.js';
import Cbd from './Cbd.js';
import Accessories from './Accessories.js';
// import ContactUs from './ContactUs.js';
import 'bootstrap/dist/css/bootstrap.min.css';


import Navbar from './Navbar.js';


function App() {
  // databaseProducts array stays unchanged / fresh
  const [databaseProducts, setDatabaseProducts] = useState([]);
  // products array can be edited or changed.. usually cloned from databaseProducts then get updated and set to products
  const [products, setProducts] = useState([]);
  const [carts, setCarts] = useState([]);
  const [addingToCart, setAddingToCart]  = useState(false);
  const [shipping, setShipping] = useState(true);
  const [loading, setLoading] = useState(true);
  

  // SINGLE PRODUCT STATE
  const [selectionError, setSelectionError] = useState('');

  // console.log('before or after use effect?');

  useEffect(()=>{
    // console.log('USEEFFECT AT APP.JS');
    // get products / list from firebase database
    setLoading(true);
    const productQ = query(collection(db, 'products'));

    const unsub = onSnapshot(productQ, (querySnapshot)=>{
      let databaseProducts = [];

      querySnapshot.forEach((d)=>{
        const product = d.data();
        product.uid = d.id;
        databaseProducts.push(product);
      });

      setDatabaseProducts(databaseProducts);

      // localStorage.setItem('products', JSON.stringify(databaseProducts));

      const cloneProducts = structuredClone(databaseProducts);


      
      // cloneProducts[0].inStock = 29;
      // console.log('vvvvvvvvvvvv database list', databaseProducts[0].inStock);
      // console.log('vvvvvvvvvvvv clone list', cloneProducts[0].inStock);

      // setProducts(cloneProducts);
      // console.log('1, in useEffect after setProduct', products.length);

      // get cart items from local storage
      const shoppingCarts = JSON.parse(localStorage.getItem('carts'));

      console.log(shoppingCarts);
      
      if(shoppingCarts != null){
        console.log('XXXXXXXXXXXXX SHOPPING CART LEN', shoppingCarts);
        setCarts(shoppingCarts);
        // setTesttest('test JJJJJJJJJJJJJJJJJJJJJJ');
        // console.log('KKKKKKKKKKKK AFTER SET CART', carts );
        // console.log('TTTTTTTTTTTTTTTTTT', testtest);
        // console.log('CARTS', carts);
        // console.log('PRODUCTS', products);
        
        updateProductAvail(cloneProducts, shoppingCarts);
      }else{
        setProducts(cloneProducts);
      }

      setLoading(false);

    });

        // console.log('TTTTTTTTTTTTTTTTTT', testtest);


  }, []);

  const findProductFromList = (uid)=>{
    // console.log('finding product with id', uid);
    // console.log('finding product list', products);
    // products.find((p)=>{
    //   console.log('list uid', p.uid);
    //   return p.uid == uid;
    // });
    var productFound = {};
    products.forEach((p)=>{
      if(p.uid == uid){
        // console.log('ZZZZZZZZZZZ IN FOR EACH FOUND?', p);
        productFound = p;
      }
    });
    return productFound;
  }

  const updateProductAvail = (databasePro, car)=>{
    console.log('ZZZZZ UPDATE LIST');
    // console.log('ZZZZZ UP CART', car);
    const pro = structuredClone(databasePro);
    // console.log('UPDATING PRODUCT AVAILABILITY', pro.length);
    // console.log('CARTS LEN', carts);
    // const cloneProducts = []
    // const copyProducts = [...pro];
    // console.log('COPIED PRODUCTS', products.length);
    pro.forEach((p)=>{
      car.forEach((c)=>{
        if(c.uid == p.uid){
          // console.log('found match', p.uid);
          // console.log('found match', c.uid);
          // console.log('P inStock', p.inStock);
          // console.log('C amount * qty', c.amount * c.qty); 
          // console.log('CART AND PRODUCT MATCHES!!');
          // console.log('BEFORE MINUS CART', p.inStock);
          p.inStock = p.inStock - (c.amount * c.qty);
          // console.log('AFTER MINUS CART', p.inStock);
        }
      });
    });
    // console.log('AFTER UPDATING PRODUCT AVAIL', copyProducts);
    console.log('YYYYYYYYY after updated list', pro);
    setProducts(pro);
    // console.log('AFTER SET PRODUCTS', products.length);
  }

  const calTotalPrice = (p)=>{
    // console.log('calculating....', p);
    const grossTotal = p.amount * p.priceGram * p.qty;
    const disc = grossTotal * p.discount;
    const trueTotal = grossTotal - disc;
    p.totalPrice = trueTotal;
    // this one returns total pirce itself not whole object
    return p.totalPrice;
  }

  const changeQty = (item, type)=>{
    // console.log('changing qty', item);
    const indexFound = carts.findIndex((el)=>{
      return el.id == item.id
    });

    //copy state
    const copyCart =  carts.slice();

    if(type == 'minus' && item.qty > 1){
      copyCart[indexFound].qty--;
      copyCart[indexFound].totalPrice = calTotalPrice(copyCart[indexFound]);

      setCarts(copyCart);

      updateProductAvail(databaseProducts, copyCart);
      
      localStorage.setItem('carts', JSON.stringify(copyCart));
      // showAddedNumber();
    }
    if(type == 'plus' && Math.floor(item.inStock / item.amount) > item.qty){
      // console.log('plus qty');
      copyCart[indexFound].qty++;
      copyCart[indexFound].totalPrice = calTotalPrice(copyCart[indexFound]);
      setCarts(copyCart);

      updateProductAvail(databaseProducts, copyCart);

      // console.log(copyCart);
      localStorage.setItem('carts', JSON.stringify(copyCart));
    }
  }

  const clearCart = ()=>{
    localStorage.setItem('carts', null);
    setCarts([]);
  }

  const getCartTotal = ()=>{
    // console.log('calling get cart total');
    let total = 0;
    carts.forEach((item)=>{
      total = total + item.totalPrice;
    });
    return Math.floor(total);
  }

  const getShipping = ()=>{

    if(getCartTotal() > 28 ) {
      return 0;
    }else {
      return 5
    }
  }

  const getExpShipping = ()=>{

    if(getCartTotal() > 28 && getCartTotal() < 59 ) {
      return 4;
    }else if (getCartTotal() < 29) {
      return 9;
    }else {
      return 0;
    }
  }

  const getTotal = (amount, price, qty, discount)=>{
    const total = (Number(amount) * price * Number(qty));
    return (total - (total * discount));
  }
  
  const addToCart = (i)=>{
    console.log('ADDING TO CART');
    
    
    const item = structuredClone(i);

    setAddingToCart(true);
   
    setTimeout(() => {
      const copyCarts = [...carts];

      let index = null;
      // check if same item with same amount exist in cart
      copyCarts.forEach((cartI, i)=>{
      
      if(cartI.uid == item.uid && cartI.amount == item.amount){
          index = i;
        }
      });

      if(index != null){
        // if found dup / index, increace qty and update cart
        // console.log('BEFORE CAL', copyCarts[index]);
        copyCarts[index].qty = copyCarts[index].qty + item.qty;
        copyCarts[index].totalPrice = calTotalPrice(copyCarts[index]);
        // console.log('AFTER CAL', copyCarts[index]);
        // console.log('dupcart', copyCarts);
        setCarts(copyCarts);
        localStorage.setItem('carts', JSON.stringify(copyCarts));
      } else {
        // if no duplicate, add new item to cart
        //set id to item
        console.log('no dup..adding to cart');
        item.id = Date.now();
        item.totalPrice = calTotalPrice(item);

        copyCarts.push(item);

        console.log('after added to cart..carts', copyCarts);
        
        setCarts(copyCarts);
        
        localStorage.setItem('carts', JSON.stringify(copyCarts));
      }

      console.log('database product..should be untouched', databaseProducts);
      

      updateProductAvail(databaseProducts, copyCarts);

      setAddingToCart(false);
      
    }, 700);
  }


  const removeFromCart = (index)=>{
    const copyCarts = [...carts]
    copyCarts.splice(index, 1);
    setCarts(copyCarts);
    localStorage.setItem('carts', JSON.stringify(copyCarts));

    updateProductAvail(structuredClone(databaseProducts), copyCarts)
  }


  const changeShipping = (boo)=>{
    setShipping(boo);
  }

  // const findSingleProduct = (uid)=>{

  // }

  const handleSeclect = (e, uid)=>{
    const value = e.target.value;
    // console.log('handling select', value);
    // console.log('handling select', uid);

    const copyProducts = [...products]

      copyProducts.forEach((p)=>{
        if(p.uid == uid){

          // const copyProduct = {...product};
    
    // setSelectedAmount(Number(value));
    // console.log('amount selected', selectedAmount);
      //assign selected value to this product amount
      p.amount = Number(value);

      //reset this product qty after differnt amount is selected
      p.qty = 1;

      if(value == ''){
        p.discount = null;
      }
      if(value == '1'){
        p.discount = .0;
        setSelectionError('-');
      }
      if(value == '2'){
        p.discount = .05;
        setSelectionError('-');
      }
      if(value == '4'){
        p.discount = .10;
        setSelectionError('-');
      }
      if(value == '8'){
        p.discount = .15;
        setSelectionError('-');
      }
      if(value == '16'){
        p.discount = .20;
        setSelectionError('-');
      }
      if(value == '32'){
        p.discount = .25;
        setSelectionError('-');
      }
      if(value == '64'){
        p.discount = .30;
        setSelectionError('-');
      }

      p = calTotalPrice(p);
      // console.log('pp', p);
      setProducts(copyProducts);

        }
      });



    // console.log('option selected');
   
    
  }

  const addToCartErrorCheck = (uid)=>{
    console.log('adding to cart check error');

    // console.log('find found', product);

    products.forEach((p)=>{
      if(p.uid == uid){
        if(p.discount == null){
        setSelectionError('Please select an option');
        }else{
        // console.log('no error.. continue with adding to cart');
        // console.log('product', p);
        
        addToCart(p);

        // resetQty()
        }
      }
    });
    
    
  }

    const addQty = (e, uid)=>{
      // console.log('adding qty', uid);

      const copyProducts = [...products]

      copyProducts.forEach((p)=>{

        if(p.uid == uid && p.discount != null){
          // console.log(p.amount);
          // console.log('match', Math.floor(p.inStock / p.amount));

          if(p.qty < Math.floor(p.inStock / p.amount)){
            p.qty++;
            p = calTotalPrice(p);
            // console.log('????', copyProducts);
            // console.log(updatedProduct);
            setProducts(copyProducts)
          }

        }

      });
  }

      const minusQty = (e, uid)=>{
      // console.log('minus qty', uid);

      const copyProducts = [...products]

      copyProducts.forEach((p)=>{

        if(p.uid == uid && p.discount != null){

          if(p.qty > 1){
            p.qty--;
            p = calTotalPrice(p);
            setProducts(copyProducts)
          }

        }

      });
  }

  // const minusQty = ()=>{
  //   if(product.qty > 1){
  //     const copyProduct = {...product}
  //   copyProduct.qty--;
  //   const updatedProduct = calTotalPrice(copyProduct);
    
  //   setProduct(updatedProduct)
  //   }
  // }

  // const resetQty = ()=>{
    
  //     // const copyProducts = [...products]

  //     // copyProduct.forEach((p)=>{
  //     //   if(p.uid == uid){
  //     //     p.inStock = p.inStock - ()
  //     //   }
  //     // });

  //   console.log('resetting quantity', product);
  //     const copyProduct = {...product};

  //     copyProduct.inStock = copyProduct.inStock - (selectedAmount * copyProduct.qty);
  //     copyProduct.qty = 1;
  //     copyProduct.discount = null;
  //     copyProduct.amount = 0;
  //     setSelectedAmount(0);
    
  //     setProduct(copyProduct);
  //     // location.state.product = copyProduct;
  // }

  return (
    <div className="App">
      {/* <Navbar /> */}
      
      <Routes>

      <Route exact path='/' element={< Home 
        carts={carts}
        products={products}
      />}></Route>

      <Route exact path='cart' element={< Cart 
        carts={carts}
        removeFromCart={removeFromCart}
        changeQty={changeQty}
        getCartTotal={getCartTotal}
        getShipping={getShipping}
        getExpShipping={getExpShipping}
        shipping={shipping}
        changeShipping={changeShipping}
        loading={loading}
        />}>
      </Route>

      <Route exact path='/checkout' element={< Checkout 
        carts={carts}
        getCartTotal={getCartTotal}
        getShipping={getShipping}
        getExpShipping={getExpShipping}
        clearCart={clearCart}
        shipping={shipping}
      />}></Route>

      <Route exact path='/startplan' element={< StartPlan 
      
      />}></Route>

      <Route exact path='/product' element={< Product 
        addToCart={addToCart}
        addingToCart={addingToCart}
        carts={carts}
        products={products}
        updateProductAvail={updateProductAvail}
        findProductFromList={findProductFromList}
        addQty={addQty}
        handleSeclect={handleSeclect}
        minusQty={minusQty}
        addToCartErrorCheck={addToCartErrorCheck}
        selectionError={selectionError}
        />}>
      </Route>

      <Route exact path='/menu' element={< Menu />}></Route>

      <Route exact path='/thankyou' element={< ThankYou />}></Route>

      <Route exact path='/employee' element={< Employee />}></Route>

      <Route exact path='/order' element={< Order />}></Route>

      <Route exact path='/concentrates' element={< Concentrates />}></Route>

      <Route exact path='/cbd' element={< Cbd />}></Route>

      <Route exact path='/edibles' element={< Edibles />}></Route>

      <Route exact path='/contactus' element={< Contactus />}></Route>

      <Route exact path='/accessories' element={< Accessories />}></Route>

      {/* <Route exact path='/contactus' element={< ContactUs />}></Route> */}

      {/* <Route exact path='/howitworks' element={< Howitworks />}></Route> */}
    </Routes>
    
    
      
      {/* <Home /> */}
      {/* <CartBar carts={carts}/> */}
    </div>
    
  );
}

export default App;


    

    // API REQUEST

    // fetch('http://localhost:9000/.netlify/functions/get-invoice')
    //     .then(response => response.json())
    //     .then(data => console.log(data));

    // fetch('http://localhost:9000/get-invoice')
    //     .then(response => response.json())
    //     .then(data => console.log(data));

    


    // const result = await fetch('/.netlify/functions/get-invoice');
    // const data = await result.json();
    // console.log(data.results);
    // const token = "R6kphhKfeM3FFfVO2gcKoZrxxsicYOMmCYE0rq4HN4s";
    // const config = {
    //   headers: {
    //     'Authorization': `Bearer ${token}`,
    //     "Content-Type": "multipart/form-data",
    //     "Access-Control-Allow-Origin": "*"
    //   }
    // }

    // const body = {
    //   parent_id: "cccd5ee4cc364990",
    //   value: 44
    // }



    // const headers = {
    //   Authorization: "Bearer R6kphhKfeM3FFfVO2gcKoZrxxsicYOMmCYE0rq4HN4s"
    // }

    // axios.post('https://www.blockonomics.co/api/create_temp_product', body, config)
    // .then(res => console.log(res)).catch(err => console.log(err));

    // axios.get('https://www.blockonomics.co/api/price?currency=BCH')
    // .then(res => console.log(res)).catch(err => console.log(err));

    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Authorization': `Bearer ${token}` },
    //     body: JSON.stringify({ parent_uid: 'cccd5ee4cc364990', value: 55 })
    // };
    // fetch('https://www.blockonomics.co/api/create_temp_product', requestOptions)
    //     .then(response => response.json())
    //     .then(data => console.log(data));

    // fetch('https://www.blockonomics.co/api/price?currency=BCH')
    //     .then(response => response.json())
    //     .then(data => console.log(data));
