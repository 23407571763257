import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Navbar from './Navbar.js';
import CartBar from './CartBar.js';
import { BsArrowLeft } from 'react-icons/bs';
import { BsXLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';




export default function Product(props) {

  
// console.log('Product List in single product component', props.products);
  // console.log(props);
  const navigate = useNavigate();
  const location = useLocation()
  // console.log('START OF PRODUCT FUNCTION...');
  // console.log(location.state);return;
  
  if(location.state == null){
    navigate('/');
  }
  

  // console.log('PRODUCTS COUNTS', props.products.length);
 
  let paramProduct = {};
  //clone item from link location
  if(location.state != null){
    paramProduct = structuredClone(location.state.product);
  }
  
// console.log('PARAM PRODUCT INSTOCK', paramProduct);
  // console.log(uid);
  // console.log(props.products);
  
  // cloneProduct.qty = 1;

  // console.log('UID FROM LOCATION / CLONE', cloneProduct.uid);
  // console.log('INSTOCK FROM LOCATION / CLONE', cloneProduct.inStock);

  

  

  // console.log('PRODUCT LIST', props.products);


 
  // const [product, setProduct] = useState(paramProduct);
  const [error, setError] = useState('-');
  const [selectedAmount, setSelectedAmount] = useState(0);

  const foundProduct = props.products.find((eachProduct)=>{
    return eachProduct.uid == location.state.product.uid;
  });

  // console.log('FIND AND FOUND PRODUCT', foundProduct);
  // setProduct(foundProduct);

  


  // console.log('INSTOCK', product.inStock);

  useEffect(() => {
    // console.log('USE EFFECT TRIGGERED');
    // console.log('IN USE EFFECT, PRODUCT LIST', props.products);
    // props.updateProductAvail(props.products);

    // each time the page is reload, we want to fetch the product
    // from the list of products in main state, that way the product
    // avail will be in current state

    // console.log('UID TO LOOK FOR', cloneProduct.uid);

    // console.log('PRODUCT FOUND???', props.findProductFromList(cloneProduct.uid));
    
    // console.log('TEST REALLY??', props.testing());
    

    // setProduct(props.findProductFromList(cloneProduct.uid));

    window.scrollTo(0, 0);
    // console.log('PRODUCT', product);
  }, [location.pathname])

  const updateStorageProduct = ()=>{

  }
  

  // const addQty = ()=>{
  //   const copyProduct = {...product}
  //   if(copyProduct.qty < Math.floor(copyProduct.inStock / copyProduct.amount)){
  //     copyProduct.qty++;
  //     const updatedProduct = calTotalPrice(copyProduct);
  //     console.log(updatedProduct);
  //     setProduct(updatedProduct)
  //   }
    
  // }


  // const handleSeclect = (e)=>{
  //   console.log('option selected');
   
  //   const copyProduct = {...product};
  //   const value = e.target.value;
  //   setSelectedAmount(Number(value));
  //   console.log('amount selected', selectedAmount);
  //     //assign selected value to this product amount
  //     copyProduct.amount = Number(value);
  //     //reset this product amount after differnt amount is selected
  //     copyProduct.qty = 1;

  //     if(value == ''){
  //       copyProduct.discount = null;
  //     }
  //     if(value == '1'){
  //       copyProduct.discount = .0;
  //       setError('-');
  //     }
  //     if(value == '2'){
  //       copyProduct.discount = .05;
  //       setError('-');
  //     }
  //     if(value == '4'){
  //       copyProduct.discount = .10;
  //       setError('-');
  //     }
  //     if(value == '8'){
  //       copyProduct.discount = .15;
  //       setError('-');
  //     }
  //     if(value == '16'){
  //       copyProduct.discount = .20;
  //       setError('-');
  //     }
  //     if(value == '32'){
  //       copyProduct.discount = .25;
  //       setError('-');
  //     }
  //     if(value == '64'){
  //       copyProduct.discount = .30;
  //       setError('-');
  //     }

  //     const updatedProduct = calTotalPrice(copyProduct);
  //     console.log(updatedProduct);
  //     setProduct(updatedProduct);
  // }

  const calTotalPrice = (p)=>{
    // console.log('calculating....');
    const grossTotal = p.amount * p.priceGram * p.qty;
    const disc = grossTotal * p.discount;
    const trueTotal = grossTotal - disc;
    p.totalPrice = trueTotal;
    return p;
  }

  // const addToCartErrorCheck = ()=>{
    
  //   if(product.discount == null){
  //     setError('Please select an option');
  //   }else{
  //     console.log('product', product);
      
  //     props.addToCart(product);

  //     resetQty()
  //   }
  // }

  return (
    <div>

      <Navbar />

      <CartBar carts={props.carts}/>

        {props.products.map((product)=>{
          // console.log('product');
        return (
          <div key={product.uid}>
            {/* start of item box */}
            {product.uid == paramProduct.uid ? <div className="item-box">
              
      <div className="item-arrowback-box">
        <div className="product-back" onClick={()=>{navigate(-1)}}><BsArrowLeft size={40}/></div>

        <div className="product-x" onClick={()=>{navigate('/')}}><BsXLg size={25}/></div>
      </div>

      <div className="item-img-box">
        <img className="item-img" src={product.image} />
      </div>
      <div className="item-name">{product.name} </div>
      <div className="item-desc">{product.type} &#9679; {product.grade} &#9679; {product.thc}
      </div>

      <div className="item-price">
        ${product.priceGram} / 3.5g
      </div>

      <div className="price-box">
        {props.addingToCart ? <Spinner animation="border" variant="warning" style={{marginTop: '65px'}}/> : <>
        
        <div className={props.selectionError == '-' ? 'noError' : 'error'}>{props.selectionError}</div>
        <select onChange={(e)=>{props.handleSeclect(e, product.uid)}}>

          <option value="">Select an option</option>

          {/* if instock is less than option amount then disabled this option */}
          
          {/* 3.5 grams option */}
          {product.inStock == 0 ? <option disabled value="1">3.5 grams - ${product.priceGram}</option> : <option value="1">3.5 grams - ${product.priceGram}</option> }

          {/* 7 grams options */}
          {product.inStock < 2 ? <option disabled value="2">7 grams - ${Math.floor(parseFloat((2 * product.priceGram)-((2 * product.priceGram) * 0.05)))} - - - (5% off)</option> : 
          <option value="2">7 grams - ${Math.floor(parseFloat((2 * product.priceGram)-((2 * product.priceGram) * 0.05)))} - - - (5% off)</option>}


          {product.inStock < 4 ? <option disabled value="4">14 grams - ${Math.floor(parseFloat((4 * product.priceGram)-((4 * product.priceGram) * 0.10))) } - - (10% off)</option>:<option value="4">14 grams - ${Math.floor(parseFloat((4 * product.priceGram)-((4 * product.priceGram) * 0.10))) } - - (10% off)</option> }



          {product.inStock < 8 ? <option disabled value="8">28 grams - ${Math.floor(parseFloat((8 * product.priceGram)-((8 * product.priceGram) * 0.15)))} - - (15% off)</option>:<option value="8">28 grams - ${Math.floor(parseFloat((8 * product.priceGram)-((8 * product.priceGram) * 0.15)))} - - (15% off)</option> }


          {product.inStock < 16 ? <option disabled value="16">2 ounces - ${Math.floor(parseFloat((16 * product.priceGram)-((16 * product.priceGram) * 0.20)))} - - (20% off)</option>:<option value="16">2 ounces - ${Math.floor(parseFloat((16 * product.priceGram)-((16 * product.priceGram) * 0.20)))} - - (20% off)</option> }


          {product.inStock < 32 ? <option disabled value="32">4 ounces - ${Math.floor(parseFloat((32 * product.priceGram)-((32 * product.priceGram) * 0.25)))} - - (25% off)</option>:<option value="32">4 ounces - ${Math.floor(parseFloat((32 * product.priceGram)-((32 * product.priceGram) * 0.25)))} - - (25% off)</option> }


          {product.inStock < 64 ? <option disabled value="64">8 ounces - ${Math.floor(parseFloat((64 * product.priceGram)-((64 * product.priceGram) * 0.30)))} - - (30% off)</option>:<option value="64">8 ounces - ${Math.floor(parseFloat((64 * product.priceGram)-((64 * product.priceGram) * 0.30)))} - - (30% off)</option> }
         


          {/* {product.inStock < 64 ? : }
          {product.inStock < 64 ? : }
          {product.inStock < 64 ? : }
          {product.inStock < 64 ? : }
          {product.inStock < 64 ? : } */}


          

          

          

          

          

          

        </select>
          
          <div className="qty-title">Quantity: {product.amount != '' ? Math.floor(product.inStock / product.amount) + ' max' : ''}</div>
          <div className="qty-add">
            
            <div className="qty-box">
              <div className="qty-minus"  onClick={(e)=>{props.minusQty(e, product.uid)}}>-</div>

              <div className="qty-number">{product.qty}</div>

              <div className="qty-plus" onClick={(e)=>{props.addQty(e, product.uid)}}>+</div>
            </div>

            { product.inStock == 0 ? 

              <Button className="disabledBtn" disabled variant="danger"><span className="addtocart-title">Out of stock</span>
                </Button> : 
              
              <Button onClick={()=>{props.addToCartErrorCheck(product.uid)}}><span className="addtocart-title">Add to cart</span>
              </Button>

            }

          </div>
        
        </>}
        
      
        
      </div>

      <div className="strain-flavor">
        <h5>Flavors:</h5>
        <h6>Mango &bull; Tropical &bull; Tree Fruit</h6>
      </div>

      <div className="strain-helpwith">
        <h5>Help With:</h5>
        <h6>Stress &bull; Anxiety &bull; Pain</h6>
      </div>

      <div className="strain-desc">
        {product.desc}
        
        {/* <b>Papaya</b> is an indica-dominant hybrid cannabis strain that is known for producing a mental calmness, though many consumers also find that this strain makes them energetic and productive. Nirvana Seeds bred this to be their take on a Mango strain and crossed Citral #13 with Ice #2 to make their own fruit. They met their own challenge and Papaya smells uncannily like its namesake. The taste is similar to its tropical fruit counterpart, but a spicy, peppery side also becomes apparent. */}
      </div>

    </div>: ''}
            {/* end of item box */}
          </div>
        )
      })}

      

    
    
    </div>
  )
}
