import React from 'react'
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar.js';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';



export default function ThankYou(props) {
  const location = useLocation()
  console.log('thankyou props', location);
  return (
    <div>
    <Navbar />
    <div style={{padding: '80px 20px 10px 10px', margin: '10px 20px', borderBottom: '2px solid gray'}}>
      <h1>Thank you for <br/>ordering from us.</h1>
      <br/> 
      <div><h4>Your order number is <b>{location.state.orderNumber}</b>. We've also sent an email with your order details to {location.state.customer.email}.</h4></div>
    </div>
    <br/>
    <Link to="/">
    <Button >
      Home
    </Button>
    </Link>
    </div>
  )
}
