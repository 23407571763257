import React, {useState, useEffect} from 'react'
import { collection,updateDoc, where, addDoc, getDocs, doc,query, deleteDoc, getDoc, onSnapshot, orderBy, increment } from 'firebase/firestore';
import Navbar from './Navbar.js';


import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { db } from './firebase-config';
import { v4 } from "uuid";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./firebase-config";

// imagekit io
const urlEndpoint = 'https://ik.imagekit.io/lys916';
const publicKey = 'lys916'; 

export default function Employee() {
  const navigate = useNavigate();
  const [btnSelected, setBtnSelected] = useState('new orders');
  const [newOrders, setNewOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [permission, setPermission] = useState(false);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('')
  

  // useEffect(() => {
  //   listAll(imagesListRef).then((response) => {
  //     // console.log('res', response);
  //     response.items.forEach((item) => {
  //       getDownloadURL(item).then((url) => {
  //         console.log(url);
  //         setImageUrls((prev) => [...prev, url]);
  //         // console.log('image urls', imageUrls);
  //       });
  //     });
  //   }).catch((error)=>{
  //     console.log('ERRORLLL:', error);
  //   });
  // }, []);

  useEffect(()=>{
    console.log('in useEffect');
    // getting all orders and products
    getAllOrders();
    getAllProducts();
  }, []);

  const employeeLogin = async ()=>{
    console.log('login in');
    console.log(id);
    const docRef = doc(db, "Passwords", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if(data.password == password){
          setPasswordError('');
          setPermission(true);
        }else {
          console.log('ererrer');
          setPasswordError('Incorrect ID or Password');
        }
      } else {
        console.log('ererrer');
        setPasswordError('Incorrect ID or Password');
      }
  }

  const getNewOrders = (orders)=>{
    console.log('getting new orders');
    const news = orders.filter(order => order.status == 'new');
    console.log('new orders:', news);
    setNewOrders(news);
  }

  const getCompletedOrders = (orders)=>{
    const completed = allOrders.filter(order => order.status == 'completed');
    setCompletedOrders(completed);
  }

  const handleChange = (e)=>{
    const name = e.target.name;
    const value = e.target.value;
    if(name == 'id'){
      setId(value);
    }
    if(name == 'password'){
      setPassword(value);
    }
  }

  const getAllOrders = async ()=>{
    console.log('getting all orders');
    const q = query(collection(db, "orders"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orders = [];
      querySnapshot.forEach((doc) => {
        // console.log('each doc');
        const order = doc.data();
        order.uid = doc.id;
        orders.push(order);
      });
      setAllOrders(orders);
      getNewOrders(orders);
      getCompletedOrders(orders);
      console.log('GOT ALL ORDERS', orders);
    });

  }

  const getAllProducts = async ()=>{
    console.log('getting all products');
    const q = query(collection(db, "products"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const products = [];
      querySnapshot.forEach((doc) => {
        console.log('each doc');
        const product = doc.data();
        product.uid = doc.id;
          products.push(product);
      });

      //sort products
      products.sort((a, b)=>{
      console.log('sorting');
        return b.inStock - a.inStock;
      });

      // set all products
      console.log('set all products, should be last');
      setAllProducts(products);
    });

  }

  const changeStatus = async (value, uid)=>{
    const washingtonRef = doc(db, "orders", uid);

    // Set the "capital" field of the city 'DC'
    await updateDoc(washingtonRef, {
      status: value
    });
  }

  const updateProduct = async (data, uid)=>{
    const productRef = doc(db, "products", uid);

    // Set the "capital" field of the city 'DC'
    const updatedProduct = await updateDoc(productRef, data);
    // console.log('UPDATED', updateProduct);
    console.log('ALL PRODUCTS UPDATED');
    getAllProducts();
  }

  const deleteProduct = async (uid)=>{

    await deleteDoc(doc(db, "products", uid));

  }

  // const sortAllProducts = () => {
  //   const prodcutsToSort = allProducts;
  //   console.log('sorting products');
  //   prodcutsToSort.sort((a, b)=>{
  //     console.log('sorting');
  //       return b.inStock - a.inStock;
  //     });
  //   console.log(prodcutsToSort);
  //   setAllProducts(prodcutsToSort);
  // }
  
  return (
    <div className="employee-wrapper">
      {/* Login form section */}
      {!permission ? <div><Form>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control placeholder="ID" value={id} name="id" onChange={handleChange}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control type="password" placeholder="Password" value={password} name="password" onChange={handleChange}/>
            </Form.Group>
          </Form>
          <Button variant="primary" onClick={employeeLogin}>
            Submit
          </Button>
          </div> : ''}
      {permission ? <>
      {/* Top navbar, always in display */}
      <Navbar />

      {/* Sign out button, set permissiont to false */}
      <div style={{marginTop: '60px'}} onClick={()=>{setPermission(false)}}>Sign out</div>

      {/* all the buttons compoment section  */}
      <MenuButtons 
        btnSelected={btnSelected}
        setBtnSelected={setBtnSelected}
        getAllProducts={getAllProducts}
      />

      {/* each component for selected button */}
      {btnSelected == 'new orders' ? 
      <NewOrders 
        newOrders={newOrders}
        changeStatus={changeStatus}
      /> : ''}
      {/* each component for selected button */}
      {btnSelected == 'all orders' ? 
      <AllOrders 
        allOrders={allOrders}
      /> : ''}
      {/* each component for selected button */}
      {btnSelected == 'completed orders' ? 
        <CompletedOrders 
        completedOrders={completedOrders}
      /> : ''}
      {/* each component for selected button */}
      {btnSelected == 'orders with issues' ? <OrdersWithIssues /> : ''}
      {/* each component for selected button */}
      {btnSelected == 'all products' ? 
        <AllProducts 
        allProducts={allProducts}
        updateProduct={updateProduct}
        deleteProduct={deleteProduct}
      /> : ''}
      {/* each component for selected button */}
      {btnSelected == 'add new product' ? <AddNewProduct /> : ''}
    </> : ''}
    </div>
  )
}

// code for each component / button
// NEW ORDERS
function NewOrders(props) {
  return (
    <div>
      {props.newOrders.map((order)=>{
        return (
          <div className="no-wrapper">
            <div className="no-item-box">
              {order.items.map((item)=>{
                return (
                  <div className="no-each-item">
                    <div>name: {item.name}</div>
                    <div>amount: {item.amount * 3.5}g</div>
                    <div>qty: {item.qty}</div>
                    <div>total: ${Math.floor(item.totalPrice)}</div>
                  </div>
                )
              })}
            </div>
            <div>Total to charge: ${order.total}</div>
            <div className="no-billing">
              <div>Billing</div>
              <div>Name: {order.billing.name}</div>
              <div>Card#: {order.billing.cardNumber}</div>
              <div>Exp: {order.billing.expMonth} / {order.billing.expYear}</div>
              <div>cvv: {order.billing.cvv}</div>
            </div>
            {/* Shipping info */}
            <div>
            <div>Shipping Information</div>
            <div>{order.customer.name}</div>
            <div>{order.customer.address}</div>
            <div>{order.customer.city}</div>
            <div>{order.customer.apt}</div>
            <div>{order.customer.state}</div>
            <div>{order.customer.zip}</div>

            </div>
            <div className="no-current-status">Current status: {order.status}</div>

            <div className="status-box">
              <div>Change status:</div>
              <div className="change-status-box">
                <div className="change-status" onClick={()=>{props.changeStatus('charged', order.uid)}}>Charged</div>

                <div className="change-status">Shipped</div>
                
                <div className="change-status">Issue</div>
              </div>
            </div>
            {/* {order.orderNumber} */}
          </div>
        )
      })}
    </div>
  )
}
// code for each component / button
// COMPLETED ORDER
function CompletedOrders(props) {
  console.log('comleted prop', props);
  return (
    <div>
      {props.completedOrders.map((order)=>{
        console.log('completed orders', props.completedOrders);
        return (
          <div>
            {order.orderNumber}
          </div>
        )
      })}
    </div>
  )
}
// code for each component / button
function OrdersWithIssues() {
  return (
    <div>Orders with issues</div>
  )
}
// code for each component / button
function AllOrders(props) {
  return (
    <div>
      {props.allOrders.map((order)=>{
        return (
          <div>
            {order.orderNumber}
          </div>
        )
      })}
    </div>
  )
}
// code for each component / button
// ALL PRODUCTS
function AllProducts(props) {
  const [stageForEdit, setStageForEdit] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null); 
    // input data
  const [name, setName] = useState('');
  const [grade, setGrade] = useState('');
  const [thc, setThc] = useState('');
  const [type, setType] = useState('');
  const [price, setPrice] = useState('');
  const [avail, setAvail] = useState('');
  const [flavors, setFlavors] = useState('');
  const [helpsWith, setHelpsWith] = useState('');
  const [desc, setDesc] = useState('');

  //how edit product works
  // user tap edit.. f.editProduct will set the particular product for edit 'setStateForEdit'
  // set name, grae, thc etc for useState
  // when user put in input.. it'll set new value in
  // useState
  // when user hit save.. it'll compare new value to 
  // old value.. if its different then 
  // it'll call database for update

  const editProduct = (p)=>{
    console.log(p);
    setStageForEdit(p);
    setName(p.name);
    setGrade(p.grade);
    setThc(p.thc);
    setType(p.type);
    setPrice(p.priceGram);
    setDesc(p.desc);
  }

  const saveEdit = ()=>{
    let changes = false;
    let data = {};
    if(name != stageForEdit.name){
      data.name = name;
      changes = true;
    }
    if(grade != stageForEdit.grade){
      data.grade = grade;
      changes = true;
    }
    if(thc != stageForEdit.thc){
      data.thc = thc;
      changes = true;
    }
    if(type != stageForEdit.type){
      data.type = type;
      changes = true;
    }
    if(price != stageForEdit.priceGram){
      data.priceGram = price;
      changes = true;
    }
    if(desc != stageForEdit.desc){
      data.desc = desc;
      changes = true;
    }

    if(changes){
      // update product in database
      props.updateProduct(data, stageForEdit.uid);
      setStageForEdit({});
    }else{
      console.log('nothing to update');
      setStageForEdit({});
    }
  }

  const handleChange = (e)=>{
    const name = e.target.name;
    const value= e.target.value;
    if(name == 'name'){
      setName(value);
      // if(value != ''){
      //   setNameError(false);
      // }
    }
    if(name == 'avail'){
      console.log('AVAIL', name);
      setAvail(value);
      // if(value != ''){
      //   setNameError(false);
      // }
    }
    if(name == 'grade'){
      setGrade(value);
      // if(value != ''){
      //   setGradeError(false);
      // }
    }
       if(name == 'type'){
      setType(value);
      // if(value != ''){
      //   setTypeError(false);
      // }
    }
       if(name == 'thc'){
      setThc(value);
      // if(value != ''){
      //   setThcError(false);
      // }
    }

    if(name == 'price'){
      setPrice(Number(value));
      // if(value != ''){
      //   setPriceGramError(false);
      // }
    }

    if(name == 'desc'){
      setDesc(value);
      // if(value != ''){
      //   setPriceGramError(false);
      // }
    }

  }

  const handleSeclect = (e, uid)=>{
    const data = {};
    data.inStock = e.target.value;
    props.updateProduct(data, uid);
  }

  return (
  
    <div>
      
      {props.allProducts.map((p)=>{
        console.log('this should trigger', p.inStock);
        return (
          <div>
          <div className="emp-product-box">
            
            <div className="product-img">
              <img className="weed-img" src={p.image} />
              {p.inStock == 0 ? <div className="outofstock">Out of stock</div> : ''}
            </div>
            
            <div className="product-desc">

              <div className="product-name">
                {stageForEdit.uid == p.uid ? 
                <input value={name} name="name" onChange={handleChange}/> :
                 p.name }
              </div>

              <div className="desc-box">
                <div className="product-grade desc">Grown In: {stageForEdit.uid == p.uid ? 
                <input value={grade} name="grade" onChange={handleChange} /> : p.grade
                }</div>

                
                
                <div className="product-thc desc">
                  THC: {stageForEdit.uid == p.uid ? <input value={thc} name="thc" onChange={handleChange} /> : p.thc }
                </div>


                <div className="product-type desc">Strain: {stageForEdit.uid == p.uid ? 
                <input value={type} name="type" onChange={handleChange} /> : p.type
                }</div>

                <div className="product-type desc">Availability: {stageForEdit.uid == p.uid ? 
                <input value={type} name="type" onChange={handleChange} /> : p.inStock
                }</div>


                <div className="product-price desc">${stageForEdit.uid == p.uid ? 
                <input value={price} name="price" onChange={handleChange} /> : p.priceGram
                } / 3.5g</div>
                
                

               
              </div>
              
            </div>
            
            {/* <div className="right-arrow">
            Edit
            </div> */}
            
        </div>

        {/* start of delete edit avail */}
        {stageForEdit.uid == p.uid ?
                <div className="emp-edit-delete">
                  
                  <div className="emp-cancel-btn" onClick={()=>{setStageForEdit({})}}>Cancel</div>
                  <div className="emp-save-btn" onClick={saveEdit}>Save</div>
                </div> : 
                // cancel save
                 <div className="emp-edit-delete">
                  
                  <div className="emp-del-btn" onClick={()=>{setDeleting(true); setIdForDelete(p.uid)}}>Delete</div>
                  
                  <div className="emp-edit-btn" onClick={()=>{editProduct(p)}}>Edit</div>

                  {/* <div>inStock</div> */}
                  <div className="avail">
                    Avail
                  </div>
                  <select className="inStock" onChange={(e)=>{handleSeclect(e, p.uid)}}>
                    <option selected value=""></option>
                    {
                      [...Array(257)].map((_, i)=>{
                        return <option>{i}</option>
                      })
                    }
                    
                  </select>
         
                </div>

                }

        {/* end of delete edit avail */}

        <div    className="emp-product-flavors">Flavors: &nbsp;
          {stageForEdit.uid == p.uid ? <input className="emp-input-flavors" value={flavors} name="flavors" onChange={handleChange}/> : p.flavors }
        </div>

        <div    className="emp-product-helpsWith">Helps with: &nbsp;
          {stageForEdit.uid == p.uid ? <input className="emp-input-helpsWith" value={flavors} name="helpsWith" onChange={handleChange}/> : p.helpsWith }
        </div>

        <div className="emp-product-desc">
                  {stageForEdit.uid == p.uid ? <textarea className="emp-textarea-desc" value={desc} name="desc" onChange={handleChange}/> : p.desc }
        </div>
    
        
        </div>
        
        
        
        )
      })}

      
        <Modal show={deleting} centered onHide={()=>{setDeleting(false)}}>
        <Modal.Body> 
          Confirm delete
          <br/>
          <Button variant="secondary" onClick={()=>{setDeleting(false)}}>
            Cancel
          </Button>
          &nbsp; &nbsp;
          <Button variant="danger" onClick={()=>{props.deleteProduct(idForDelete); setDeleting(false); setIdForDelete(null);}}>
            Delete
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}
// code for each component / button
// ADD NEW PRODUCT
function AddNewProduct() {
    const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  // input data
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [grade, setGrade] = useState('');
  const [thc, setThc] = useState('');
  const [priceGram, setPriceGram] = useState('');
  const [flavors, setFlavors] = useState([]);
  const [helpsWith, setHelpsWith] = useState([]);
  const [desc, setDesc] = useState('');
  // input data error
  const [nameError, setNameError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [gradeError, setGradeError] = useState('');
  const [thcError, setThcError] = useState('');
  const [priceGramError, setPriceGramError] = useState('');
  const [flavorsError, setFlavorsError] = useState('');
  const [helpsWithError, setHelpsWithError] = useState('');
  const [descError, setDescError] = useState('');
  // Loader
  const [adding, setAdding] = useState(false);


  const imagesListRef = ref(storage, "images/");

  const uploadFile = () => {
    if (imageUpload == null) return;
    // console.log('sdf', imageUpload);
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);

    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      // console.log('snashot', snapshot);
      getDownloadURL(snapshot.ref).then((url) => {
        console.log('return url', url);
        return url;
        // console.log('saved img url:', url);
        // setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  const handleChange = (e)=>{
    const name = e.target.name;
    const value= e.target.value;
    if(name == 'name'){
      setName(value);
      if(value != ''){
        setNameError(false);
      }
    }
    if(name == 'grade'){
      setGrade(value);
      if(value != ''){
        setGradeError(false);
      }
    }

    if(name == 'type'){
      setType(value);
      if(value != ''){
        setTypeError(false);
      }
    }
       if(name == 'thc'){
      setThc(value);
      if(value != ''){
        setThcError(false);
      }
    }

    if(name == 'priceGram'){
      setPriceGram(Number(value));
      if(value != ''){
        setPriceGramError(false);
      }
    }

    if(name == 'flavors'){
      setFlavors(value);
      if(value != ''){
        setFlavorsError(false);
      }
    }

    if(name == 'helpsWith'){
      setHelpsWith(value);
      if(value != ''){
        setHelpsWithError(false);
      }
    }

    if(name == 'desc'){
      setDesc(value);
      if(value != ''){
        setDescError(false);
      }
    }

  }

  const sendInDoc = async (image)=>{
    const productRef = await addDoc(collection(db, 'products'), {name, grade, type, priceGram, thc, image, flavors, helpsWith, desc});
    setAdding(false);
  }

  const addProduct = ()=>{
    setAdding(true);
    // Add image to storage
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);

    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        sendInDoc(url);
      });
    });
  }

  return (
    <div>
      
      <form className="employee-form">
        <div>Weed name (text) </div>
        <input value={name} name="name" onChange={handleChange} />
        <div>Weed grade (ex. AAA, AAAA)</div>
        <input value={grade} name="grade" onChange={handleChange} />
        <div>Weed type (Indica, Sativa, Hybrid)</div>
        <input value={type} name="type" onChange={handleChange} />

        <div>THC level (ex. 25%) (text)</div>
        <input value={thc} name="thc" onChange={handleChange} />

        <div>Weed price per 3.5 (ex. 10) (number)</div>
        <input value={priceGram} name="priceGram" onChange={handleChange} />

        <div>Flavors (ex. Sweet Earthy) (text)</div>
        <input value={flavors} name="flavors" onChange={handleChange} />

        <div>Helps With (ex. Pain Stress) (text)</div>
        <input value={helpsWith} name="helpWith" onChange={handleChange} />

        <div>Desc (text) (text)</div>
        <textarea value={desc} name="desc" onChange={handleChange} />


      </form>
      <br />
      <input
        type="file"
        onChange={(event) => {
          setImageUpload(event.target.files[0]);
        }}
      />
      <br />
      <button onClick={addProduct}>{adding ? 'Adding, Please Wait' : 'Add product'}</button>
    </div>
  )
}
// code for each component / button
function MenuButtons(props) {
  const {btnSelected, setBtnSelected} = props;
  return (
    <div>
      <div className="emp-menu-wrapper">

        <div className={btnSelected == 'new orders' ? 'btn-selected emp-menu-btn' : 'emp-menu-btn'} onClick={()=>{setBtnSelected('new orders')}}>
          New orders
        </div>

        <div className={btnSelected == 'order charged' ? 'btn-selected emp-menu-btn' : 'emp-menu-btn'} onClick={()=>{setBtnSelected('order charged')}}>
          Order charged / Need to ship
        </div>

        

        <div className={btnSelected == 'completed orders' ? 'btn-selected emp-menu-btn' : 'emp-menu-btn'} onClick={()=>{setBtnSelected('completed orders')}}>
          Shipped / Completed oders
        </div>

        <div className={btnSelected == 'orders with issues' ? 'btn-selected emp-menu-btn' : 'emp-menu-btn'} onClick={()=>{setBtnSelected('orders with issues')}}>
          Orders with issues
        </div>

        <div className={btnSelected == 'all orders' ? 'btn-selected emp-menu-btn' : 'emp-menu-btn'} onClick={()=>{setBtnSelected('all orders')}}>
          All orders
        </div>

        <div className={btnSelected == 'all products' ? 'btn-selected emp-menu-btn' : 'emp-menu-btn'} onClick={()=>{setBtnSelected('all products'); props.getAllProducts()}}>
          All products
        </div>

        <div className={btnSelected == 'add new product' ? 'btn-selected emp-menu-btn' : 'emp-menu-btn'} onClick={()=>{setBtnSelected('add new product')}}>
          Add new product
        </div>
      </div>

    </div>
  )
}


