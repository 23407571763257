import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react'

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';

import { doc, getDoc } from "firebase/firestore";

import { db } from './firebase-config';

import { useNavigate } from 'react-router-dom';

export default function Navbar() {

const navigate = useNavigate();

  const [menuStatus, setMenuStatus] = useState('')
  const [isOpen, setOpen] = useState(false)

  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('')

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const employeeLogin = async ()=>{
    console.log('login in');
    console.log(id);
    const docRef = doc(db, "Passwords", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if(data.password == password){
          setPasswordError('');
          navigate('/employee');
        }else {
          console.log('ererrer');
          setPasswordError('Incorrect ID or Password');
        }
      } else {
        console.log('ererrer');
        setPasswordError('Incorrect ID or Password');
      }
  }

  const handleChange = (e)=>{
    const name = e.target.name;
    const value = e.target.value;
    if(name == 'id'){
      setId(value);
    }
    if(name == 'password'){
      setPassword(value);
    }
  }


  return (
    <div className='app-navbar'>
      <div className="desktop">Desktop version coming soon.</div>
       <div className={menuStatus + ' menu'}>
        <div className="app-navbar">
          <Link className="logo-link" to='/'><div className='logo'><span className="logo-letter">CG</span></div></Link>
          <div className='burger-box'  onClick={()=>{
            console.log('slfkdj', isOpen);
          if(!isOpen){
            setMenuStatus('open')
          }else{
            setMenuStatus('close')
          }
          
        }}>
            <Hamburger size={30} toggled={isOpen} toggle={setOpen}/>

          </div>
        </div>
        <br/>
        <br/>
        <br/>

      <div className='menu-list' onClick={()=>{
        if(isOpen){
          setMenuStatus('close')
          setOpen();
        }  
      }}>
        <Link to="/" className='menu-list'>FLOWERS</Link><br/>
        
      </div>

      <div className='menu-list' onClick={()=>{
        if(isOpen){
          setMenuStatus('close')
          setOpen();
        }  
      }}>
        <Link to="/concentrates" className='menu-list'>
          CONCENTRATES
        </Link><br/>
        
      </div>

      <div className='menu-list' onClick={()=>{
        if(isOpen){
          setMenuStatus('close')
          setOpen();
        }  
      }}>
        <Link to="/edibles" className='menu-list'>
          EDIBLES
        </Link><br/>
        
      </div>

      <div className='menu-list' onClick={()=>{
        if(isOpen){
          setMenuStatus('close')
          setOpen();
        }  
      }}>
        <Link to="/cbd" className='menu-list'>
          CBD
        </Link><br/>
        
      </div>

      <div className='menu-list' onClick={()=>{
        if(isOpen){
          setMenuStatus('close')
          setOpen();
        }  
      }}>
        <Link to="/accessories" className='menu-list'>
          ACCESSORIES
        </Link><br/>
        
      </div>

      <div className='menu-list' onClick={()=>{
        if(isOpen){
          setMenuStatus('close')
          setOpen();
        }  
      }}>
        <Link to="/contactus" className='menu-list'>
          CONTACT US
        </Link><br/>
        
      </div>

      <div className='menu-list' onClick={()=>{
        if(isOpen){
          setMenuStatus('close')
          setOpen();
        }  
      }}>
        <Link to="/order" className='menu-list'>
          ORDER STATUS
        </Link><br/>
        
      </div>

        

        
        
        
        

      
        </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Body> 
          <div style={{height: "20px", margin: '0px 0px 10px 3px', color: 'red'}}>{passwordError}</div>
          <Form>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control placeholder="ID" value={id} name="id" onChange={handleChange}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control type="password" placeholder="Password" value={password} name="password" onChange={handleChange}/>
            </Form.Group>
          </Form>

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          &nbsp; &nbsp;
          <Button variant="primary" onClick={employeeLogin}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

