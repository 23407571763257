import React from 'react'
import Navbar from './Navbar.js';


export default function Cbd() {
  return (
    <div>
      <Navbar />
      <div style={{marginTop: '70px'}}>
        Cbd products coming soon.
      </div>
    </div>
  )
}