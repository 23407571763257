import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC5gxtxLaerJYXRB4XkmtHOsOCElTBPHv4",
  authDomain: "cated-351522.firebaseapp.com",
  projectId: "cated-351522",
  storageBucket: "cated-351522.appspot.com",
  messagingSenderId: "462778858610",
  appId: "1:462778858610:web:1ec89effc65de6289056eb",
  measurementId: "G-DG1555VWB2"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);