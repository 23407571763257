import React, {useState} from 'react';

import { useLocation } from 'react-router-dom';
import Navbar from './Navbar.js';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
// firebase
import { doc, getDoc } from "firebase/firestore";
import { db } from './firebase-config';




export default function Order(props) {
  const [orderNumber, setOrderNumber] = useState('');
  const [orderNumberError, setOrderNumberError] = useState(false);
  const [orderNumberInvalid, setOrderNumberInvalid] = useState(false);
  const [orderStatus, setOrderStatus] = useState('');
  const [orderEmail, setOrderEmail] = useState('');
  const [orderTracking, setOrderTracking] = useState('');


  const location = useLocation()
  console.log('thankyou props', location);






  const handleChange = (e)=>{
    const value= e.target.value;
      setOrderNumber(value);
      setOrderNumberError(false);
      setOrderNumberInvalid(false);
      setOrderStatus('');
      setOrderEmail('');
      setOrderTracking('');
  }

  const handleCheckStatus = async ()=>{
    console.log('checking status', orderNumber);
    if(orderNumber == ''){
      setOrderNumberError(true);
      
    }else{

    const docRef = doc(db, "orders", orderNumber.toUpperCase());
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setOrderStatus(docSnap.data().status);
        setOrderEmail(docSnap.data().customer.email);
        setOrderTracking(docSnap.data().tracking);
      } else {
        // docSnap.data() will be undefined in this case
        setOrderNumberInvalid(true);
        console.log("No such document!");
      }

    }

   

  }


  return (
    <div>
    <Navbar />
    <div style={{padding: '80px 20px 10px 10px', margin: '10px 20px', borderBottom: '2px solid gray'}}>
      <h5>Enter your order number to check the status</h5>
      
      <Form>

      <Form.Group className="checkout-form-wrapper" controlId="formBasicEmail">

        <div className="form-input-title">*
        {orderNumberError ? <span style={{color: `red`, fontStyle: 'italic'}}> Please enter order number</span> : '' }
        {orderNumberInvalid ? <span style={{color: `red`, fontStyle: 'italic'}}> Order number invalid</span> : '' }
        </div>
        <Form.Control size="lg" placeholder="Order Number" onChange={handleChange} value={orderNumber} name="orderNumber" />


        </Form.Group>
        </Form>

      <br/>
      <br/>
      <Button onClick={handleCheckStatus}>
      Check status
    </Button>
    <br/>
      <br/> 
    </div>
    <br/>
    
    <br/>
    {/* <Link to="/">
    <Button >
      Home
    </Button>
    </Link> */}
    <div className="status-title">STATUS:</div>
    <br/>
    <div>
      {orderStatus == 'new' || orderStatus == 'charged' ? <div className="status-desc">Your order is currently being processed. Once your order is shipped, we'll email you the tracking number to {orderEmail}. <br/>Thank you.</div> : ''}

      {orderStatus == 'shipped' ? <div>Your order has been shipped. Your tracking # is {orderTracking}</div> : ''}
    </div>
    </div>
  )
}
