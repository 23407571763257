import React, {useState} from 'react';
import './StartPlan.css';
import { useLocation } from 'react-router-dom';

export default function StartPlan() {
  const [startPlanStep, setStartPlanStep] = useState(1);
  const location = useLocation();
  console.log(location.state.plan);
  const [selectedPlan, setSelectedPlan] = useState(location.state.plan);
  return (
    <div className="start-plan">
      <div className="sp-box">
        <div className={startPlanStep > 0 ? 'sp-step green' : 'sp-step gray'}>Select Meals</div>
        <div className={startPlanStep > 1 ? 'sp-step green' : 'sp-step gray'}>Address</div>
        <div className={startPlanStep > 2 ? 'sp-step green' : 'sp-step gray'}>Payment</div>
        <div className={startPlanStep > 3 ? 'sp-step green' : 'sp-step gray'}>Checkout</div>
      </div>
      <div>{selectedPlan}</div>
    </div>
  )
}
