import React, {useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { BsArrowLeft } from 'react-icons/bs';
import { BsXLg } from 'react-icons/bs';
import logo from './IMG/square-logo.png';
import Form from 'react-bootstrap/Form';
import validator from 'card-validator';
import emailjs from '@emailjs/browser';
import { collection,updateDoc, where, setDoc, addDoc, getDocs, doc,query, deleteDoc, getDoc, onSnapshot, orderBy, increment } from 'firebase/firestore';
import { db } from './firebase-config';

import QRCode from 'react-qr-code';

import { BitcoinPaymentButton } from 'react-bitcoin-payment-button';

import Spinner from 'react-bootstrap/Spinner';

export default function Checout(props) {
  const navigate = useNavigate();

  const [paymentType, setPaymentType] = useState('Card');
  const [savePayment, setSavePayment] = useState(false);
  const [placingOrder, setPlacingOrder] = useState(false);

  // Form input data
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [apt, setApt] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [email, setEmail] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState('');
  const [expMonth, setExpMonth] = useState('');
  const [expYear, setExpYear] = useState('');
  const [cvv, setCvv] = useState('');

  // Error handling 
  const [nameError, setNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameOnCardError, setnameOnCardError] = useState(false);
  const [cardNumberError, setCardNumberError] = useState('');
  const [expMonthError, setExpMonthError] = useState(false);
  const [expYearError, setExpYearError] = useState(false);
  const [cvvError, setCvvError] = useState(false);

  // Functions
  const handleChange = (e)=>{
    const name = e.target.name;
    const value= e.target.value;
     if(name == 'name'){
      setName(value);
      if(value != ''){
        setNameError(false);
      }
     }
     if(name == 'address'){
      setAddress(value);
      if(value != ''){
        setAddressError(false);
      }
     }
     if(name == 'apt'){
      setApt(value);
     }
     if(name == 'city'){
      setCity(value);
      if(value != ''){
        setCityError(false);
      }
     }
     if(name == 'state'){
      setState(value);
      if(value != ''){
        setStateError(false);
      }
     }
     if(name == 'zip'){
      if(value.length < 6 && isNaN(value) == false){
        setZip(value);
      }
      
      if(value != ''){
        setZipError(false);
      }
     }
     if(name == 'email'){
      setEmail(value);
      if(value != ''){
        setEmailError(false);
      }
     }
     if(name == 'nameOnCard'){
      setNameOnCard(value);
      if(value != ''){
        setnameOnCardError(false);
      }
     }

     if(name == 'cardNumber'){
      const Card = validator.number(value);
      
      setCardNumber(value);
        if(value == ''){
          setCardNumberError('');
          setCardType('');
        }
        if(Card.card){
          setCardType(' - ' + Card.card.niceType);
        }
            
        if(!Card.isPotentiallyValid){
          setCardNumberError(' - Card invalid');
          setCardType('');
        }else{
          setCardNumberError('');
        }
     }

     if(name == 'expMonth'){
      // console.log('selecting month');
      setExpMonth(value);
      if(value != ''){
        setExpMonthError(false);
      }
     }
     if(name == 'expYear'){
      // console.log('selecting year');
      setExpYear(value);
      if(value != ''){
        setExpYearError(false);
      }
     }
     if(name == 'cvv'){
      if(value.length < 4 && isNaN(value) == false){
        setCvv(value);
      }
      
      if(value != ''){
        setCvvError(false);
      }
     }
  }

  

  // Get final order recept / data
  const getOrderReceipt = ()=>{
    // generate random number between 1000 - 9999
      const orderNumber = Math.floor(Math.random() * 8999 + 1000).toString();
      let shippingType = '';
      if(props.shipping){
        shippingType = 'standard';
      }else {shippingType = 'expedited'};
      
      const customer = {
        name, address, apt, city, state, zip, email, shippingType
      };
      const billing = {
        name: nameOnCard,
        cardNumber,
        cardType,
        expMonth,/* #region EMPLOYEE NEW ORDERS */

/* #endregion */
        expYear,
        cvv
      };

      const initial = name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()

      const order = {};
      order.orderNumber = initial + orderNumber;
      order.status = 'new';
      order.customer = customer;
      order.billing = billing;
      order.items = props.carts;
      order.total = props.getCartTotal() + props.getShipping();

      return order;
  }

  // Check Form Error
  const checkFormError = ()=>{
    // console.log('checkout form error');
    const error = false;
    if(name == ''){ setNameError(true)}
    if(address == ''){ setAddressError(true)}
    if(city == ''){ setCityError(true)}
    if(state == ''){ setStateError(true)}
    if(zip == ''){ setZipError(true)}
    if(email == ''){ setEmailError(true)}
    if(nameOnCard == ''){ setnameOnCardError(true)}
    if(cardNumber == ''){ setCardNumberError(true)}
    if(expMonth == ''){ setExpMonthError(true)}
    if(expYear == ''){ setExpYearError(true)}
    if(cvv == ''){ setCvvError(true)}
    

    const inputs = [name, address, city, state, zip, email, nameOnCard, cardNumber, expMonth, expYear];

    let formError = false;
    inputs.forEach((input)=>{
      
      if(input == ''){
        formError = true;
      }
      
    });
    // console.log(formError);
    return formError;

  }

  const sendEmail = (order) => {

    // console.log(order);
    console.log('at send email');

    emailjs.send('service_716jlsb', 'template_xewdzqa', order, 'sPcEfSuHOe5RTkbPl')
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });

    // emailjs.sendForm('service_716jlsb', 'template_yei60tl', {data: 'test data'}, 'sPcEfSuHOe5RTkbPl')
    //   .then((result) => {
    //       console.log(result.text);
    //   }, (error) => {
    //       console.log(error.text);
    //   });
  };

  const sendOrderToDatabase = async (order)=>{
    // const orderRef = await addDoc(collection(db, 'orders'), order);
   

    await setDoc(doc(db, "orders", order.orderNumber), order);
    setPlacingOrder(false);
    navigate('/thankyou', {state: order});
  }

  const placeOrder = ()=>{
    // console.log(checkFormError());
    // return;

    if(!checkFormError()){
      setPlacingOrder(true);
      const order = getOrderReceipt();
      sendEmail(order);
      sendOrderToDatabase(order);
      props.clearCart();
    }else{
      console.log('Theres an error in the form');
    }
  }


  return (
    <div className="checkout-wrapper">
      
    {placingOrder ? <div className="overlay-placeorder"><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><Spinner animation="border" variant="success"/>
    </div> : ''}
    

    <div className="item-arrowback-box">
        <div className="cart-back" onClick={()=>{navigate(-1)}}><BsArrowLeft size={40}/></div>

        <div className="cart-x-nav" onClick={()=>{navigate('/')}}><BsXLg size={25}/></div>
      </div>

    {/* FORM DATA */}

           {props.carts.length > 0 ? <> 
      
      <div className="total-wrapper shadow-bottom">
        <div className="cart-total-wrapper">

          <div className="cart-total-box">
            <div>Cart total</div>
            <div>${props.getCartTotal()}</div>
          </div>

          <div className="cart-total-box">
            {props.shipping ? <div>Shipping (5-7 days)</div> : ''}
            {!props.shipping ? <div>Shipping (2-3 days)</div> : ''}

            {props.shipping ? <div>{props.getShipping() == 0 ? 'Free' : '$' + props.getShipping()}</div> : ''}
            {!props.shipping ? <div>{props.getExpShipping() == 0 ? 'Free' : '$' + props.getExpShipping()}</div> : ''}
          </div>

        </div>

        {props.shipping ? <div className="cart-grandtotal">
          <div>Total</div>
          <div>${props.getCartTotal()+props.getShipping()}</div>
        </div> : <div className="cart-grandtotal">
          <div>Total</div>
          <div>${props.getCartTotal()+props.getExpShipping()}</div>
        </div>}

        {/* <div className="cart-grandtotal">
          <div>Total</div>
          <div>${props.getCartTotal() + props.getShipping()}</div>
        </div> */}

      </div>
<br/>
      </> : ''}

    {/* <form className="checkout-form" onSubmit={this.handleSubmit}> */}
    
      <Form>
      <div className="delivery-title">Shipping Information</div>
      <Form.Group className="checkout-form-wrapper" controlId="formBasicEmail">

        <div className="form-input-title">Name
        {nameError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
        </div>
        <Form.Control size="lg" onChange={handleChange} value={name} name="name" />

          <div className="form-input-title">Address
          {addressError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
          </div>
        <Form.Control size="lg" onChange={handleChange} value={address} name="address" />

        <div className="form-input-title">Apt / Suite 
          {addressError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
          </div>
        <Form.Control size="lg" placeholder="optional" onChange={handleChange} value={apt} name="apt" />

        {/* <Form.Control size="lg" placeholder="Apt. Suite, Unit, (optional)" /> */}

        <div className="form-input-title">City
          {cityError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
        </div>
        <Form.Control size="lg"  onChange={handleChange} value={city} name="city" />

        <div className="state-zip-wrapper">
          <div className="state-box">
            <div className="form-input-title">State
            {stateError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
            </div>

  

            <Form.Select size="lg" aria-label="Default select example" onChange={handleChange} name="state">
            <option value=""></option>
            <option value="AL">AL</option>
            <option value="AZ">AZ</option>
            <option value="AR">AR</option>
            <option value="CA">CA</option>
            <option value="CO">CO</option>
            <option value="CT">CT</option>
            <option value="DE">DE</option>
            <option value="FL">FL</option>
            <option value="GA">GA</option>
            <option value="ID">ID</option>
            <option value="IL">IL</option>
            <option value="IN">IN</option>
            <option value="IA">IA</option>
            <option value="KS">KS</option>
            <option value="KY">KY</option>
            <option value="LA">LA</option>
            <option value="ME">ME</option>
            <option value="MD">MD</option>
            <option value="MA">MA</option>
            <option value="MI">MI</option>
            <option value="MN">MN</option>
            <option value="MS">MS</option>
            <option value="MO">MO</option>
            <option value="MT">MT</option>
            <option value="NE">NE</option>
            <option value="NV">NV</option>
            <option value="NH">NH</option>
            <option value="NJ">NJ</option>
            <option value="NM">NM</option>
            <option value="NY">NY</option>
            <option value="NC">NC</option>
            <option value="ND">ND</option>
            <option value="OH">OH</option>
            <option value="OK">OK</option>
            <option value="OR">OR</option>
            <option value="PA">PA</option>
            <option value="RI">RI</option>
            <option value="SC">SC</option>
            <option value="SD">SD</option>
            <option value="TN">TN</option>
            <option value="TX">TX</option>
            <option value="UT">UT</option>
            <option value="VT">VT</option>
            <option value="VA">VA</option>
            <option value="WA">WA</option>
            <option value="WV">WV</option>
            <option value="WI">WI</option>
            <option value="WY">WY</option>
          </Form.Select>
          </div>

          

          <div className="zip-box">
            <div className="form-input-title">Zip Code
            {zipError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
            </div>
            <Form.Control size="lg" onChange={handleChange} value={zip} name="zip"  />
          </div>
        </div>

        <div className="form-input-title">Email
        {emailError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
        </div>
        <Form.Control size="lg" onChange={handleChange} value={email} name="email"  />

      <br/>

      <div className="billing-title">Billing Information</div>

      <div className="square-payment">
        Secured Payment By <span className="square-logo"></span> Square
      </div>

{/* <div className="payment-type-title">Payment type</div> */}


{/* <div className="payment-type-wrapper">
  <div className="payment-type-box">
  <Form.Check type="checkbox" checked={paymentType == 'Card' ? true : false}  label="Card" onClick={()=>{setPaymentType('Card')}} />
  </div>

  <div className="payment-type-box">
  <Form.Check type="checkbox" checked={paymentType == 'Venmo' ? true : false}   label="Venmo" onClick={()=>{setPaymentType('Venmo')}} />
  </div>

  <div className="payment-type-box">
  <Form.Check type="checkbox" checked={paymentType == 'Cashapp' ? true : false}   label="Cashapp" onClick={()=>{setPaymentType('Cashapp')}} />
  </div>

</div> */}

{/* CARD PAYMENT */}
  {paymentType == 'Card' ? <> 
    <div className="form-input-title">Name on card
    {nameOnCardError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
    </div>
        <Form.Control size="lg"  onChange={handleChange} value={nameOnCard} name="nameOnCard"  />

      <div className="form-input-title">Card number
      <span style={{color: `red`, fontStyle: 'italic'}}>
                    {cardNumberError}
                  </span>

      <span style={{fontStyle: 'italic'}}>
        {cardType}
      </span>

      </div>
        <Form.Control size="lg" onChange={handleChange} value={cardNumber} name="cardNumber" />

      <div className="state-zip-wrapper">
        <div className="state-box">
          <div className="form-input-title">Exp. Month
          {expMonthError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
          </div>

          <Form.Select size="lg" aria-label="Default select example" onChange={handleChange} name="expMonth">
            <option value=""></option>
            <option value="1">01</option>
            <option value="2">02</option>
            <option value="3">03</option>
            <option value="3">04</option>
            <option value="3">05</option>
            <option value="3">06</option>
            <option value="3">07</option>
            <option value="3">08</option>
            <option value="3">09</option>
            <option value="3">10</option>
            <option value="3">11</option>
            <option value="3">12</option>
          </Form.Select>

        </div>

        <div className="zip-box">
          <div className="form-input-title">Exp. Year
          {expYearError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
          </div>

          <Form.Select size="lg" aria-label="Default select example" name="expYear" onChange={handleChange}>
            <option value=""></option>
            <option value="22">2022</option>
            <option value="23">2023</option>
            <option value="24">2024</option>
            <option value="24">2025</option>
            <option value="24">2026</option>
            <option value="24">2027</option>
            <option value="24">2028</option>
          </Form.Select>

           

        </div>

        <div className="cvv-box">
          <div className="form-input-title">CVV
          {cvvError ? <span style={{color: `red`, fontStyle: 'italic'}}> *</span> : '' }
          </div>

          <Form.Control size="lg" onChange={handleChange} value={cvv} name="cvv" />

        </div>
      </div>

      <div className="save-payment-wrapper">
        <div className="payment-type-box">
          <Form.Check type="checkbox" checked={savePayment}  label="Save payment details for later use" onClick={()=>{savePayment ? setSavePayment(false) : setSavePayment(true)}} />
        </div>
        </div>
  
  </> : ''}


  {/* VENMO PAYMENT */}
  {paymentType == 'Venmo' || paymentType == 'Cashapp' ? 
  <div className="other-payment-msg">
    To pay with {paymentType}, please place your order and you will receive an order # on the next screen and payment instructions. 
  </div>

  : ''}

  
      

        
      </Form.Group>

      {/* <Button variant="primary" type="submit">
        Submit
      </Button> */}
    </Form>


       {props.carts.length > 0 ? <> 
      <br/>
      {/* <Link to='/checkout'> */}
      <Button variant="success" size="lg" onClick={placeOrder}><span className="proceed-checkout-name">Place order</span></Button>


      <br/>
      {/* <button onClick={sendEmail}>Send email</button> */}
      {/* </Link> */}
      <br/>< br/>
      <br/>< br/>

  

      {/* <a href="" class="blockoPayBtn" data-toggle="modal" data-uid=cccd5ee4cc364990><img width=160 src="https://www.blockonomics.co/img/pay_with_bitcoin_medium.png"></a> */}

      {/* <BitcoinPaymentButton 
        uid="cccd5ee4cc364990-98c17726" 
  
      /> */}


      </> : ''}
    </div>
  )
}
