import React from 'react'
import Navbar from './Navbar.js';


export default function Contactus() {
  return (
    <div>
      <Navbar />
      <div style={{marginTop: '70px'}}>
        Contact us coming soon.
      </div>
    </div>
  )
}